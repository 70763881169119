import fetchRequest from '../auth/FetchInterceptorRequests'

const studentService = {}

studentService.getAll = function () {
	return fetchRequest({
		url: '/digital/students',
		method: 'get'
	})
}

studentService.getAllByMonth = function (month,year,uid=0) {
	return fetchRequest({
		url: `/digital/students/${month}/${year}/${uid}`,
		method: 'get'
	})
}


studentService.get = function (data) {
	return fetchRequest({
		url: `/digital/students/${data.id}`,
		method: 'get'
	})
}
studentService.createStudent = function (data) {
	return fetchRequest({
	  url: '/digital/students',
	  method: 'post',
	  data: data
	})
  }


studentService.statistic = function (data) {
	return fetchRequest({
		url: `/digital/students/statistic`,
		params: data,
		method: 'get'
	})
}

studentService.update = function (data) {
	return fetchRequest({
		url: `/digital/students/${data.id}`,
		method: 'put',
		data: data
	})
}
studentService.delete = function (id) {
	return fetchRequest({
		url: `/digital/students/${id}`,
		method: 'delete'
	})
}
// Add these functions to your existing StudentService object

/**
 * Get students by array of IDs
 * 
 * @param {Array<string>} studentIds - Array of student IDs
 * @returns {Promise} Promise with array of student data
 */
studentService.getStudentsByIds = function(studentIds) {
	return fetchRequest({
	  url: `/digital/students/batch`,
	  method: 'post',
	  data: { ids: studentIds }
	})
  }
  
  /**
   * Get students with login information for the specified time period
   * 
   * @param {number} month - Month number (1-12)
   * @param {number} year - Year
   * @param {string} userId - Optional user ID to filter by
   * @returns {Promise} Promise with student login data
   */
  studentService.getStudentsWithLogins = function(month, year, userId = null) {
	const url = userId 
	  ? `/digital/students/with-logins/${month}/${year}/${userId}`
	  : `/digital/students/with-logins/${month}/${year}`;
	
	return fetchRequest({
	  url: url,
	  method: 'get'
	})
  }
  
  /**
   * Get login metrics for student
   * 
   * @param {string} studentId - Student ID
   * @returns {Promise} Promise with login metrics
   */
  studentService.getStudentLoginMetrics = function(studentId) {
	return fetchRequest({
	  url: `/digital/students/${studentId}/login-metrics`,
	  method: 'get'
	})
  }
export default studentService;