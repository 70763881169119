import fetchRequest from '../auth/FetchInterceptorRequests'

const translationService = {
    getTranslations: function () {
        return fetchRequest({
            url: '/digital/translations',
            method: 'get'
        })
    },

    saveTranslations: function (translations) {
        return fetchRequest({
            url: '/digital/translations',
            method: 'post',
            data: { translations }
        })
    },

    getCountries: function () {
        return fetchRequest({
            url: '/digital/countries',
            method: 'get'
        })
    },
 

//new translationService

 // Get all translations
 getAll: () => {
    return fetchRequest({
      url: '/translations',
      method: 'get'
    });
  },

  // Get translations by language
  getByLanguage: (languageCode) => {
    return fetchRequest({
      url: `/translations/language/${languageCode}`,
      method: 'get'
    });
  },

  // Get translations for a specific module (e.g., 'digital')
  getByModule: (module) => {
    return fetchRequest({
      url: `/translations/module/${module}`,
      method: 'get'
    });
  },

  // Create a new translation
  create: (translationData) => {
    return fetchRequest({
      url: '/translations',
      method: 'post',
      data: translationData
    });
  },

  // Update a translation
  update: (id, translationData) => {
    return fetchRequest({
      url: `/translations/${id}`,
      method: 'put',
      data: translationData
    });
  },

  // Delete a translation
  delete: (id) => {
    return fetchRequest({
      url: `/translations/${id}`,
      method: 'delete'
    });
  },

  // Import translations from CSV/JSON
  importTranslations: (file, module) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('module', module);

    return fetchRequest({
      url: '/translations/import',
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  // Export translations to CSV/JSON
  exportTranslations: (module, format = 'json') => {
    return fetchRequest({
      url: `/translations/export/${module}?format=${format}`,
      method: 'get',
      responseType: 'blob'
    });
  }

};

export default translationService;