import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Printer, Download } from 'lucide-react';
import QRCode from 'qrcode.react';
import html2pdf from 'html2pdf.js';
import { useTranslation } from '../../../hooks/useTranslation';
import { STORAGE_BASE_URL } from '../../../configs/AppConfig';
import tinkerLabsLogo from '../../../assets/images/logos/logo.png';
import coursesService from '../../../services/CoursesService';
import moment from 'moment';

// Default fallback color if no color is found
const DEFAULT_COLOR = '#8884d8';

// Get weekday name
const getWeekdayName = (date) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[new Date(date).getDay()];
};

// Format time from 24h to 12h with AM/PM
const formatTime = (timeString) => {
  if (!timeString) return '';
  return moment(timeString, 'HH:mm').format('HH:mm');
};

const PrintCardModal = ({ isOpen, toggle, groupData, students }) => {
  const { t } = useTranslation();
  const [cardsData, setCardsData] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch all courses for color mapping
    const fetchCourses = async () => {
      try {
        const coursesData = await coursesService.getAll();
        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    
    fetchCourses();
  }, []);

  useEffect(() => {
    if (groupData && students && courses.length > 0) {
      // Find the course for this group
      const course = courses.find(c => c._id === groupData.course);
      
      // Get license data from the first license in the list (or use default values)
      const licenseData = course?.licenseData && course.licenseData.length > 0 
        ? course.licenseData[0] 
        : null;
      
      // Get course name from license data or fallback to course name
      const courseName = licenseData?.name || course?.name || 'Default Course';
      
      // Get course color from license data or use default color
      const courseColor = licenseData?.color || DEFAULT_COLOR;
      
      // Get day and time info
      const weekdayName = getWeekdayName(groupData.start);
      const timeStart = formatTime(groupData.timestart);
      const timeEnd = formatTime(groupData.timeend);
      const timeInfo = `${weekdayName} ${timeStart} - ${timeEnd} h`;

      // Prepare data for cards
      const data = students.map(student => ({
        id: student._id,
        name: `${student.name} ${student.lname}`,
        group: groupData.name,
        qrValue: student?.qr,
        timeInfo: timeInfo,
        courseColor: courseColor,
        courseName: courseName
      }));
      setCardsData(data);
    }
  }, [groupData, students, courses]);

  // Define styles with card size of 7x4 cm
  const styles = {
    cardContainer: {
      width: '7cm',
      height: '4cm',
      position: 'relative',
      border: '1px dashed #ccc',
      margin: 0,
      padding: 0,
      pageBreakInside: 'avoid',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    cardRow: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1
    },
    qrSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px',
      width: '35%'
    },
    contentSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '8px',
      width: '65%'
    },
    studentName: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom: '4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    groupName: {
      fontSize: '11px',
      marginBottom: '4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    timeInfo: {
      fontSize: '10px',
      color: '#555',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    bottomRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      height: '30px'
    },
    colorBar: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '8px'
    },
    logo: {
      height: '80px',
      position: 'absolute',
      bottom: '-12px',
      right: '-12px',
      opacity: 0.8
    },
    buttonContainer: {
      marginBottom: '12px',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  };

  const handlePrint = () => {
    const style = document.createElement('style');
    style.innerHTML = `
      @media print {
        @page {
          size: A4;
          margin: 0.5cm;
        }

        body * {
          visibility: hidden;
        }

        #printable-cards, 
        #printable-cards * {
          visibility: visible;
        }

        #printable-cards {
          position: absolute;
          left: 0;
          top: 0;
          width: 100% !important;
        }

        .card-container {
          width: 7cm !important;
          height: 4cm !important;
          margin: 0 !important;
          border: 1px dashed #ccc !important;
          page-break-inside: avoid !important;
          break-inside: avoid !important;
          position: relative !important;
        }

        .color-bar {
          display: block !important;
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          width: 100% !important;
          height: 8px !important;
          visibility: visible !important;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
          color-adjust: exact !important;
        }

        /* Fix the wrapper layout */
        #printable-cards > div {
          display: grid !important;
          grid-template-columns: repeat(2, 7cm) !important;
          gap: 0 !important;
          width: 14cm !important;
          margin: 0 auto !important;
          padding: 0 !important;
        }

        /* Each card wrapper */
        #printable-cards > div > div {
          width: 7cm !important;
          height: 4cm !important;
          padding: 0 !important;
          margin: 0 !important;
          page-break-inside: avoid !important;
          break-inside: avoid !important;
        }

        /* Force page break after every 10 cards (5 rows) */
        #printable-cards > div > div:nth-child(10n) {
          page-break-after: always !important;
          break-after: page !important;
        }
      }
    `;
    document.head.appendChild(style);

    setTimeout(() => {
      window.print();
      document.head.removeChild(style);
    }, 500);
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('printable-cards');
    
    // Create temporary CSS for PDF generation
    const originalStyle = element.style.cssText;
    
    // Set the width more precisely
    element.style.width = '14cm';
    element.style.maxWidth = '14cm';
    element.style.margin = '0 auto';
    
    const opt = {
      margin: 0.5,
      filename: `${groupData.name}-cards.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2, 
        useCORS: true,
        letterRendering: true,
        logging: false
      },
      jsPDF: { 
        unit: 'cm', 
        format: 'a4', 
        orientation: 'portrait',
        compress: true
      },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    html2pdf().set(opt).from(element).save().then(() => {
      // Restore original style
      element.style.cssText = originalStyle;
    });
  };

  if (!groupData || !students) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {t('Print Cards for')} {groupData.name}
      </ModalHeader>
      <ModalBody>
        <div style={styles.buttonContainer}>
         {/*  <Button color="primary" className="me-2" onClick={handlePrint}>
            <Printer size={16} className="me-1" /> {t('Print')}
          </Button> */}
          <Button color="success" onClick={handleDownloadPDF}>
            <Download size={16} className="me-1" /> {t('Download PDF')}
          </Button>
        </div>

        <div id="printable-cards" style={{margin: '0 auto', padding: 0, width: '14cm', maxWidth: '14cm'}}>
          <div style={{
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 7cm)',
            gap: 0,
            margin: 0, 
            padding: 0, 
            width: '14cm', 
            maxWidth: '14cm'
          }}>
            {cardsData.map((card, index) => (
              <div key={card.id} style={{
                width: '7cm', 
                height: '4cm', 
                margin: 0, 
                padding: 0,
              }}>
                <div className="card-container" style={styles.cardContainer}>
                  <div className="color-bar" style={{ ...styles.colorBar, backgroundColor: card.courseColor }}></div>
                  <div style={{...styles.cardRow, paddingTop: '8px'}}>
                    <div style={styles.qrSection}>
                      <QRCode
                        value={card.qrValue}
                        size={90}
                        level="H"
                        includeMargin={false}
                        renderAs="svg"
                      />
                    </div>
                    <div style={styles.contentSection}>
                      <div style={styles.studentName}>{card.name}</div>
                      <div style={styles.groupName}>{card.courseName}</div>
                      <div style={styles.timeInfo}>{card.timeInfo}</div>
                    </div>
                  </div>
                  <div style={styles.bottomRow}>
                    <img 
                      src={tinkerLabsLogo || `${STORAGE_BASE_URL}/assets/images/logos/logo.png`} 
                      alt="Tinkerlabs Logo" 
                      style={styles.logo}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PrintCardModal;