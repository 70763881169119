import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  ComposedChart
} from 'recharts';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import { useTranslation } from '../../../hooks/useTranslation';

const LoginActivityChart = ({ data, month, year }) => {
  const { t } = useTranslation();
  const [chartType, setChartType] = useState('bar');
  const [dataType, setDataType] = useState('logins');
  
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  // Format the data for display
  const formattedData = data.map(day => ({
    day: day.day,
    total: day.total || 0,
    pin: day.pin || 0,
    qr: day.qr || 0,
    demo: day.demo || 0,
    regular: day.regular || 0,
    students: day.students || 0, // Ensure students field exists
  }));
  
  // Get chart configuration based on the selected type
  const getChartConfig = () => {
    if (chartType === 'bar') {
      return (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={formattedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="day" 
              label={{ value: t('Day of Month'), position: 'insideBottomRight', offset: 0 }} 
            />
            <YAxis yAxisId="left" orientation="left" label={{ value: t('Logins'), angle: -90, position: 'insideLeft' }} />
            {dataType === 'both' && (
              <YAxis yAxisId="right" orientation="right" label={{ value: t('Students'), angle: 90, position: 'insideRight' }} />
            )}
            <Tooltip 
              formatter={(value, name) => {
                if (name === 'students') return [value, t('Unique Students')];
                if (name === 'total') return [value, t('Total Logins')];
                if (name === 'pin') return [value, t('PIN Logins')];
                if (name === 'qr') return [value, t('QR Logins')];
                if (name === 'demo') return [value, t('Demo Logins')];
                if (name === 'regular') return [value, t('Regular Logins')];
                return [value, name];
              }}
            />
            <Legend />
            
            {(dataType === 'logins' || dataType === 'both') && (
              <>
                <Bar dataKey="total" fill="#fb9678" name={t('Total Logins')} yAxisId="left" />
                <Bar dataKey="pin" fill="#03a9f3" name={t('PIN Logins')} yAxisId="left" />
                <Bar dataKey="qr" fill="#00c292" name={t('QR Logins')} yAxisId="left" />
              </>
            )}
            
            {(dataType === 'students' || dataType === 'both') && (
              <Bar dataKey="students" fill="#5C6BC0" name={t('Unique Students')} yAxisId={dataType === 'both' ? 'right' : 'left'} />
            )}
          </BarChart>
        </ResponsiveContainer>
      );
      //eslint-disable-next-line
    }else if (chartType === 'line') {
      return (
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={formattedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="day" 
              label={{ value: t('Day of Month'), position: 'insideBottomRight', offset: 0 }} 
            />
            <YAxis yAxisId="left" orientation="left" label={{ value: t('Logins'), angle: -90, position: 'insideLeft' }} />
            {dataType === 'both' && (
              <YAxis yAxisId="right" orientation="right" label={{ value: t('Students'), angle: 90, position: 'insideRight' }} />
            )}
            <Tooltip 
              formatter={(value, name) => {
                if (name === 'students') return [value, t('Unique Students')];
                if (name === 'total') return [value, t('Total Logins')];
                if (name === 'pin') return [value, t('PIN Logins')];
                if (name === 'qr') return [value, t('QR Logins')];
                return [value, name];
              }}
            />
            <Legend />
            
            {(dataType === 'logins' || dataType === 'both') && (
              <>
                <Line type="monotone" dataKey="total" stroke="#fb9678" name={t('Total Logins')} yAxisId="left" />
                <Line type="monotone" dataKey="pin" stroke="#03a9f3" name={t('PIN Logins')} yAxisId="left" />
                <Line type="monotone" dataKey="qr" stroke="#00c292" name={t('QR Logins')} yAxisId="left" />
              </>
            )}
            
            {(dataType === 'students' || dataType === 'both') && (
              <Line 
                type="monotone" 
                dataKey="students" 
                stroke="#5C6BC0" 
                strokeWidth={3}
                name={t('Unique Students')} 
                yAxisId={dataType === 'both' ? 'right' : 'left'} 
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (chartType === 'composed') {
      return (
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            data={formattedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="day" 
              label={{ value: t('Day of Month'), position: 'insideBottomRight', offset: 0 }} 
            />
            <YAxis yAxisId="left" orientation="left" label={{ value: t('Logins'), angle: -90, position: 'insideLeft' }} />
            <YAxis yAxisId="right" orientation="right" label={{ value: t('Students'), angle: 90, position: 'insideRight' }} />
            <Tooltip 
              formatter={(value, name) => {
                if (name === 'students') return [value, t('Unique Students')];
                if (name === 'total') return [value, t('Total Logins')];
                return [value, name];
              }}
            />
            <Legend />
            <Bar dataKey="total" fill="#fb9678" name={t('Total Logins')} yAxisId="left" />
            <Line 
              type="monotone" 
              dataKey="students" 
              stroke="#5C6BC0" 
              strokeWidth={3}
              name={t('Unique Students')} 
              yAxisId="right" 
            />
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
    
    return null;
  };
  
  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5>{t('Login Activity for')} {t(months[month])} {year}</h5>
          <div>
            <ButtonGroup className="me-3">
              <Button
                color={chartType === 'bar' ? 'primary' : 'secondary'}
                onClick={() => setChartType('bar')}
              >
                {t('Bar')}
              </Button>
              <Button
                color={chartType === 'line' ? 'primary' : 'secondary'}
                onClick={() => setChartType('line')}
              >
                {t('Line')}
              </Button>
              <Button
                color={chartType === 'composed' ? 'primary' : 'secondary'}
                onClick={() => setChartType('composed')}
              >
                {t('Combined')}
              </Button>
            </ButtonGroup>
            
            <ButtonGroup>
              <Button
                color={dataType === 'logins' ? 'primary' : 'secondary'}
                onClick={() => setDataType('logins')}
              >
                {t('Logins')}
              </Button>
              <Button
                color={dataType === 'students' ? 'primary' : 'secondary'}
                onClick={() => setDataType('students')}
              >
                {t('Students')}
              </Button>
              <Button
                color={dataType === 'both' ? 'primary' : 'secondary'}
                onClick={() => setDataType('both')}
                disabled={chartType === 'composed'}
              >
                {t('Both')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        
        {getChartConfig()}
        
        <div className="text-center mt-3">
          <small className="text-muted">
            {t('This chart displays login activity and unique student count for each day of the month.')}
          </small>
        </div>
      </CardBody>
    </Card>
  );
};

export default LoginActivityChart;