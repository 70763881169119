import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Card, CardBody, CardTitle, Container, Form, FormGroup, Label, Input, Alert, Badge } from 'reactstrap';
import { AlertCircle, User, Key, QrCode, Calendar, Clock, Monitor, Smartphone, UserCheck } from 'lucide-react';
import { Select } from 'antd';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import studentLoginService from '../../../services/StudentLoginService';
import studentService from '../../../services/StudentService';
import centerService from '../../../services/CenterService';
import countryService from '../../../services/CountryService';
import { useTranslation } from '../../../hooks/useTranslation';
import LoginActivityChart from './LoginActivityChart';

const { Option } = Select;

// Styled components
const TableContainer = styled.div`
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
`;

const TableHeader = styled.thead`
  background-color: #f8f9fa;
  color: #495057;
  th {
    padding: 16px;
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 0.05em;
  }
`;

const TableBody = styled.tbody`
  tr {
    &:nth-child(even) {
      background-color: #f8f9fa;
    }
    &:hover {
      background-color: #e9ecef;
    }
  }
  td {
    padding: 12px 16px;
    border-top: 1px solid #dee2e6;
    font-size: 0.95rem;
  }
`;

const CenterName = styled.td`
  font-weight: 600;
  color: #495057;
  font-size: 1.1rem !important;
`;

const TotalRow = styled.tr`
  background-color: #fb9678a3 !important;
  border-bottom: 2px solid #ced4da; 
  font-weight: 600;
`;

const GrandTotalRow = styled.tr`
  background-color: #fb9678 !important;
  font-weight: 600;
`;

const NumberCell = styled.td`
  text-align: right;
`;

const DetailCard = styled(Card)`
  margin-bottom: 20px;
`;

const LoginTypeCell = styled.td`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const DeviceTypeCell = styled.td`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const StyledBadge = styled(Badge)`
  font-size: 0.8rem;
  padding: 6px 10px;
  border-radius: 12px;
  margin-left: 8px;
`;

const LoginLimitWarning = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 8px;
  border-left: 4px solid #ffc107;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const TabButton = styled(Button)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const FilterCard = styled(Card)`
  margin-bottom: 20px;
`;

const StudentLoginReport = () => {
  const { t } = useTranslation();
  const [loginData, setLoginData] = useState({
    students: {},
    totalLogins: 0,
    totalStudents: 0,
    pinLogins: 0,
    qrLogins: 0,
    demoLogins: 0,
    regularLogins: 0,
    mobileLogins: 0,
    desktopLogins: 0,
    dailyStats: [],
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [viewMode, setViewMode] = useState('summary');
  const [centers, setCenters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [students, setStudents] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectedLoginType, setSelectedLoginType] = useState('all');
  const [loginDataByDay, setLoginDataByDay] = useState([]);
  const [activeTab, setActiveTab] = useState('summary');

  const contentRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const months = [
    { name: t('January'), value: 0 },
    { name: t('February'), value: 1 },
    { name: t('March'), value: 2 },
    { name: t('April'), value: 3 },
    { name: t('May'), value: 4 },
    { name: t('June'), value: 5 },
    { name: t('July'), value: 6 },
    { name: t('August'), value: 7 },
    { name: t('September'), value: 8 },
    { name: t('October'), value: 9 },
    { name: t('November'), value: 10 },
    { name: t('December'), value: 11 }
  ];
  
  const organizeLoginData = (data) => {
    const { logins, studentData } = data;
    const organizedData = {};
    let totalLogins = 0;
    let totalStudents = 0;
    let pinLogins = 0;
    let qrLogins = 0;
    let demoLogins = 0;
    let regularLogins = 0;
    let mobileLogins = 0;
    let desktopLogins = 0;
    
    // Set to track all unique student IDs across all centers
    const uniqueStudentIds = new Set();
    
    // Group logins by student
    logins.forEach(login => {
      const studentId = login.student_id;
      const student = studentData.find(s => s._id === studentId);
      
      if (!student) return;
      
      // Add to unique students set
      uniqueStudentIds.add(studentId);
      
      const centerName = centers.find(c => c.lcf === student.lcf)?.name || 'Unknown Center';
      
      if (!organizedData[centerName]) {
        organizedData[centerName] = {
          students: {},
          totalLogins: 0,
          totalStudents: 0,
          pinLogins: 0,
          qrLogins: 0,
          demoLogins: 0,
          regularLogins: 0,
          mobileLogins: 0,
          desktopLogins: 0
        };
      }
      
      if (!organizedData[centerName].students[studentId]) {
        organizedData[centerName].students[studentId] = {
          name: `${student.name} ${student.lname}`,
          logins: [],
          totalLogins: 0,
          pinLogins: 0,
          qrLogins: 0,
          isDemoStudent: student.is_demo || false
        };
        
        organizedData[centerName].totalStudents++;
      }
      
      // Add login to student
      organizedData[centerName].students[studentId].logins.push(login);
      organizedData[centerName].students[studentId].totalLogins++;
      
      // Update login type counts
      if (login.login_type === 'pin') {
        organizedData[centerName].students[studentId].pinLogins++;
        organizedData[centerName].pinLogins++;
        pinLogins++;
      } else if (login.login_type === 'qr') {
        organizedData[centerName].students[studentId].qrLogins++;
        organizedData[centerName].qrLogins++;
        qrLogins++;
      }
      
      // Update student type counts
      if (login.is_demo) {
        organizedData[centerName].demoLogins++;
        demoLogins++;
      } else {
        organizedData[centerName].regularLogins++;
        regularLogins++;
      }
      
      // Update device type counts
      if (login.browser_info?.mobile) {
        organizedData[centerName].mobileLogins++;
        mobileLogins++;
      } else {
        organizedData[centerName].desktopLogins++;
        desktopLogins++;
      }
      
      // Update total login counts
      organizedData[centerName].totalLogins++;
      totalLogins++;
    });
    
    // Set total students from uniqueStudentIds
    totalStudents = uniqueStudentIds.size;
    
    // Generate daily stats
    const dailyStats = [];
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    
    // Create an index of student IDs by day for quicker lookup
    const studentIdsByDay = Array(daysInMonth).fill().map(() => new Set());
    
    // First pass: organize logins by day
    logins.forEach(login => {
      const loginDate = new Date(login.created_at);
      if (loginDate.getMonth() === selectedMonth && loginDate.getFullYear() === selectedYear) {
        const day = loginDate.getDate() - 1; // 0-based index
        studentIdsByDay[day].add(login.student_id);
      }
    });
    
    // Second pass: create daily stats
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(selectedYear, selectedMonth, day);
      const dateString = date.toISOString().split('T')[0];
      
      const dayLogins = logins.filter(login => {
        const loginDate = new Date(login.created_at);
        return loginDate.getDate() === day && 
               loginDate.getMonth() === selectedMonth && 
               loginDate.getFullYear() === selectedYear;
      });
      
      // Get unique students count from pre-calculated sets
      const uniqueStudentsCount = studentIdsByDay[day - 1].size;
      
      dailyStats.push({
        date: dateString,
        totalLogins: dayLogins.length,
        uniqueStudents: uniqueStudentsCount,
        pinLogins: dayLogins.filter(login => login.login_type === 'pin').length,
        qrLogins: dayLogins.filter(login => login.login_type === 'qr').length,
        demoLogins: dayLogins.filter(login => login.is_demo).length,
        regularLogins: dayLogins.filter(login => !login.is_demo).length,
        mobileLogins: dayLogins.filter(login => login.browser_info?.mobile).length,
        desktopLogins: dayLogins.filter(login => !login.browser_info?.mobile).length
      });
    }
    
    return {
      students: organizedData,
      totalLogins,
      totalStudents,
      pinLogins,
      qrLogins,
      demoLogins,
      regularLogins,
      mobileLogins,
      desktopLogins,
      dailyStats
    };
  };

  const fetchCountriesAndCenters = async () => {
    try {
      const [countriesData, centersData] = await Promise.all([
        countryService.getAll(),
        centerService.getAll()
      ]);
      setCountries(countriesData);
      setCenters(centersData);
    } catch (error) {
      console.error('Error fetching countries and centers:', error);
      setError('Failed to fetch countries and centers. Please try again.');
    }
  };
  
  const calculateLoginsByDay = (logins) => {
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const loginsByDay = Array(daysInMonth).fill(0).map((_, index) => ({
      day: index + 1,
      total: 0,
      uniqueStudents: 0,
      pin: 0,
      qr: 0,
      demo: 0,
      regular: 0,
      mobile: 0,
      desktop: 0
    }));
    
    // Create a map to track unique student IDs for each day
    const studentIdsByDay = Array(daysInMonth).fill().map(() => new Set());
    
    // Process all logins in a single pass
    logins.forEach(login => {
      const loginDate = new Date(login.created_at);
      
      if (loginDate.getMonth() === selectedMonth && loginDate.getFullYear() === selectedYear) {
        const day = loginDate.getDate() - 1; // Adjust for 0-based array index
        
        // Update login counts
        loginsByDay[day].total++;
        
        // Add student to the unique set for this day
        studentIdsByDay[day].add(login.student_id);
        
        // Update login type counts
        if (login.login_type === 'pin') {
          loginsByDay[day].pin++;
        } else if (login.login_type === 'qr') {
          loginsByDay[day].qr++;
        }
        
        // Update student type counts
        if (login.is_demo) {
          loginsByDay[day].demo++;
        } else {
          loginsByDay[day].regular++;
        }
        
        // Update device type counts
        if (login.browser_info?.mobile) {
          loginsByDay[day].mobile++;
        } else {
          loginsByDay[day].desktop++;
        }
      }
    });
    
    // Update unique students count for each day
    studentIdsByDay.forEach((studentIds, day) => {
      loginsByDay[day].uniqueStudents = studentIds.size;
    });
    
    return loginsByDay;
  };
  
  const fetchLoginData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Get the first and last day of the selected month
      const startDate = new Date(selectedYear, selectedMonth, 1);
      const endDate = new Date(selectedYear, selectedMonth + 1, 0);
      
      // Format dates as ISO strings
      const startDateString = startDate.toISOString();
      const endDateString = endDate.toISOString();
      
      // Fetch login data for the selected month
      const data = await studentLoginService.getLoginsByDateRange(startDateString, endDateString);
      
      // Fetch student data for the logins
      const studentIds = [...new Set(data.map(login => login.student_id))];
      const studentData = await studentService.getStudentsByIds(studentIds);
      
      // Organize the data
      const organizedData = organizeLoginData({
        logins: data,
        studentData
      });
      
      setLoginData(organizedData);
      
      // Calculate logins by day for the chart
      const loginsByDay = calculateLoginsByDay(data);
      setLoginDataByDay(loginsByDay);
      
    } catch (error) {
      console.error('Error fetching login data:', error);
      setError('Failed to fetch login data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `Student Login Report - ${months[selectedMonth].name} ${selectedYear}`,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        fetchLoginData().then(() => {
          resolve();
        });
      });
    },
    onAfterPrint: () => {
      console.log('Printing completed');
    },
  });

  useEffect(() => {
    if (centers.length > 0) {
      fetchLoginData();
    }
  }, [centers, selectedMonth, selectedYear, selectedLoginType]);

  useEffect(() => {
    fetchCountriesAndCenters();
    let savedViewMode = localStorage.getItem('studentLoginReportViewMode');
    if (!savedViewMode) {
      savedViewMode = 'summary';
      localStorage.setItem('studentLoginReportViewMode', savedViewMode);
    }
    setViewMode(savedViewMode);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const centersInCountry = centers.filter(center => center.country_data._id === selectedCountry);
      setFilteredCenters(centersInCountry);
    } else {
      setFilteredCenters(centers);
    }
  }, [selectedCountry, centers]);

  const handleMonthChange = (monthValue) => {
    setSelectedMonth(monthValue);
    
    // When moving from January to December, go to previous year
    if (monthValue === 11 && selectedMonth === 0) {
      setSelectedYear(selectedYear - 1);
    }
    // When moving from December to January, go to next year
    else if (monthValue === 0 && selectedMonth === 11) {
      setSelectedYear(selectedYear + 1);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(Number(event.target.value));
  };

  const filterLoginData = (data) => {
    if (!selectedCountry && selectedCenters.length === 0 && selectedLoginType === 'all') return data;
    
    const filteredStudents = Object.entries(data.students).reduce((acc, [centerName, centerData]) => {
      // Filter by center
      if (selectedCenters.length > 0 && !selectedCenters.includes(centerName)) {
        return acc;
      }
      
      // Filter login type
      let filteredCenterData = { ...centerData };
      
      if (selectedLoginType !== 'all') {
        filteredCenterData = {
          ...filteredCenterData,
          students: Object.entries(centerData.students).reduce((studentAcc, [studentId, studentData]) => {
            // Filter logins by login type
            const filteredLogins = studentData.logins.filter(login => {
              if (selectedLoginType === 'pin') return login.login_type === 'pin';
              if (selectedLoginType === 'qr') return login.login_type === 'qr';
              if (selectedLoginType === 'demo') return login.is_demo;
              if (selectedLoginType === 'regular') return !login.is_demo;
              if (selectedLoginType === 'mobile') return login.browser_info?.mobile;
              if (selectedLoginType === 'desktop') return !login.browser_info?.mobile;
              return true;
            });
            
            if (filteredLogins.length > 0) {
              studentAcc[studentId] = {
                ...studentData,
                logins: filteredLogins,
                totalLogins: filteredLogins.length,
                pinLogins: filteredLogins.filter(login => login.login_type === 'pin').length,
                qrLogins: filteredLogins.filter(login => login.login_type === 'qr').length
              };
            }
            
            return studentAcc;
          }, {})
        };
        
        // Recalculate center totals
        const students = Object.values(filteredCenterData.students);
        filteredCenterData.totalLogins = students.reduce((sum, student) => sum + student.totalLogins, 0);
        filteredCenterData.totalStudents = students.length;
        filteredCenterData.pinLogins = students.reduce((sum, student) => sum + student.pinLogins, 0);
        filteredCenterData.qrLogins = students.reduce((sum, student) => sum + student.qrLogins, 0);
      }
      
      if (Object.keys(filteredCenterData.students).length > 0) {
        acc[centerName] = filteredCenterData;
      }
      
      return acc;
    }, {});
    
    // Recalculate grand totals
    let totalLogins = 0;
    let totalStudents = 0;
    let pinLogins = 0;
    let qrLogins = 0;
    let demoLogins = 0;
    let regularLogins = 0;
    let mobileLogins = 0;
    let desktopLogins = 0;
    
    Object.values(filteredStudents).forEach(centerData => {
      totalLogins += centerData.totalLogins;
      totalStudents += centerData.totalStudents;
      pinLogins += centerData.pinLogins;
      qrLogins += centerData.qrLogins;
      demoLogins += centerData.demoLogins;
      regularLogins += centerData.regularLogins;
      mobileLogins += centerData.mobileLogins;
      desktopLogins += centerData.desktopLogins;
    });
    
    return {
      ...data,
      students: filteredStudents,
      totalLogins,
      totalStudents,
      pinLogins,
      qrLogins,
      demoLogins,
      regularLogins,
      mobileLogins,
      desktopLogins
    };
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem('studentLoginReportViewMode', mode);
  };

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    setSelectedCountry(countryId);
    setSelectedCenters([]);
  };

  const handleCenterChange = (eventIds) => {
    setSelectedCenters(eventIds);
  };

  const handleLoginTypeChange = (type) => {
    setSelectedLoginType(type);
  };

  const renderFilters = () => (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="countrySelect">{t('Select Country')}</Label>
            <Input
              type="select"
              name="country"
              id="countrySelect"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              <option value="">{t('All Countries')}</option>
              {countries.map(country => (
                <option key={country._id} value={country._id}>{country.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="centerSelect">{t('Select Centers')}</Label>
            <Select
              mode="multiple"
              name="centers"
              id="centers"
              style={{ width: '100%' }}
              placeholder={t('Centers')}
              value={selectedCenters}
              onChange={handleCenterChange}
            >
              {filteredCenters.map((center) => (
                <Option key={center._id} value={center.name}>{center.name}</Option>
              ))}
            </Select>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label>{t('Login Type')}</Label>
            <div>
              <Button 
                color={selectedLoginType === 'all' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('all')}
                className="me-2 mb-2"
              >
                {t('All')}
              </Button>
              <Button 
                color={selectedLoginType === 'pin' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('pin')}
                className="me-2 mb-2"
              >
                <Key size={16} className="me-1" />
                {t('PIN')}
              </Button>
              <Button 
                color={selectedLoginType === 'qr' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('qr')}
                className="me-2 mb-2"
              >
                <QrCode size={16} className="me-1" />
                {t('QR')}
              </Button>
              <Button 
                color={selectedLoginType === 'demo' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('demo')}
                className="me-2 mb-2"
              >
                {t('Demo')}
              </Button>
              <Button 
                color={selectedLoginType === 'regular' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('regular')}
                className="me-2 mb-2"
              >
                {t('Regular')}
              </Button>
              <Button 
                color={selectedLoginType === 'mobile' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('mobile')}
                className="me-2 mb-2"
              >
                <Smartphone size={16} className="me-1" />
                {t('Mobile')}
              </Button>
              <Button 
                color={selectedLoginType === 'desktop' ? 'primary' : 'secondary'} 
                onClick={() => handleLoginTypeChange('desktop')}
                className="me-2 mb-2"
              >
                <Monitor size={16} className="me-1" />
                {t('Tablet')}
              </Button>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );

  const renderSummaryView = () => {
    if (isLoading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{error}</div>;
    if (Object.keys(loginData.students).length === 0) return <div>{t('No data available')}</div>;

    const filteredData = filterLoginData(loginData);

    return (
      <>
        <TableContainer>
          <StyledTable>
            <TableHeader>
              <tr>
                <th>{t('Center')}</th>
                <th style={{ textAlign: 'right' }}>{t('Students')}</th>
                <th style={{ textAlign: 'right' }}>{t('Total')}</th>
                <th style={{ textAlign: 'right' }}>{t('PIN')}</th>
                <th style={{ textAlign: 'right' }}>{t('QR')}</th>
                <th style={{ textAlign: 'right' }}>{t('Demo')}</th>
                <th style={{ textAlign: 'right' }}>{t('Regular')}</th>
                <th style={{ textAlign: 'right' }}>{t('Mobile')}</th>
                <th style={{ textAlign: 'right' }}>{t('Tablet')}</th>
              </tr>
            </TableHeader>
            <TableBody>
              {Object.entries(filteredData.students).map(([centerName, centerData], centerIndex) => (
                <tr key={centerName}>
                  <CenterName>{centerIndex + 1}. {centerName}</CenterName>
                  <NumberCell>{centerData.totalStudents}</NumberCell>
                  <NumberCell>{centerData.totalLogins}</NumberCell>
                  <NumberCell>{centerData.pinLogins}</NumberCell>
                  <NumberCell>{centerData.qrLogins}</NumberCell>
                  <NumberCell>{centerData.demoLogins}</NumberCell>
                  <NumberCell>{centerData.regularLogins}</NumberCell>
                  <NumberCell>{centerData.mobileLogins}</NumberCell>
                  <NumberCell>{centerData.desktopLogins}</NumberCell>
                </tr>
              ))}
              <GrandTotalRow>
                <td><strong>{t('Grand Total')}</strong></td>
                <NumberCell><strong>{filteredData.totalStudents}</strong></NumberCell>
                <NumberCell><strong>{filteredData.totalLogins}</strong></NumberCell>
                <NumberCell><strong>{filteredData.pinLogins}</strong></NumberCell>
                <NumberCell><strong>{filteredData.qrLogins}</strong></NumberCell>
                <NumberCell><strong>{filteredData.demoLogins}</strong></NumberCell>
                <NumberCell><strong>{filteredData.regularLogins}</strong></NumberCell>
                <NumberCell><strong>{filteredData.mobileLogins}</strong></NumberCell>
                <NumberCell><strong>{filteredData.desktopLogins}</strong></NumberCell>
              </GrandTotalRow>
            </TableBody>
          </StyledTable>
        </TableContainer>
        
        <div className="mt-4">
          <h4>{t('Login Statistics')}</h4>
          <Row>
            <Col md={3} className="mb-3">
              <DetailCard>
                <CardBody className="text-center">
                  <h5>{t('Total Logins')}</h5>
                  <h2>{filteredData.totalLogins}</h2>
                </CardBody>
              </DetailCard>
            </Col>
            <Col md={3} className="mb-3">
              <DetailCard>
                <CardBody className="text-center">
                  <h5>{t('Total Students')}</h5>
                  <h2>{filteredData.totalStudents}</h2>
                </CardBody>
              </DetailCard>
            </Col>
            <Col md={3} className="mb-3">
              <DetailCard>
                <CardBody className="text-center">
                  <h5>{t('Average Logins per Student')}</h5>
                  <h2>
                    {filteredData.totalStudents > 0 
                      ? (filteredData.totalLogins / filteredData.totalStudents).toFixed(1) 
                      : '0'}
                  </h2>
                </CardBody>
              </DetailCard>
            </Col>
            <Col md={3} className="mb-3">
              <DetailCard>
                <CardBody className="text-center">
                  <h5>{t('Login Method Ratio')}</h5>
                  <h2>
                    {filteredData.totalLogins > 0 
                      ? `${Math.round((filteredData.pinLogins / filteredData.totalLogins) * 100)}% PIN / ${Math.round((filteredData.qrLogins / filteredData.totalLogins) * 100)}% QR` 
                      : '0% PIN / 0% QR'}
                  </h2>
                </CardBody>
              </DetailCard>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const renderDetailedView = () => {
    if (isLoading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{error}</div>;
    if (Object.keys(loginData.students).length === 0) return <div>{t('No data available')}</div>;

    const filteredData = filterLoginData(loginData);
    
    const hasExceededLoginLimit = (studentData) => {
      return !studentData.isDemoStudent && studentData.totalLogins >= 2;
    };

    return (
      <div>
        {Object.entries(filteredData.students).map(([centerName, centerData], centerIndex) => (
          <Card key={centerName} className="mb-4">
            <CardBody>
              <CardTitle tag="h5">
                {centerIndex + 1}. {centerName} 
                <span className="ms-2">
                  <StyledBadge color="primary">
                    {centerData.totalStudents} {t('Students')}
                  </StyledBadge>
                  <StyledBadge color="info">
                    {centerData.totalLogins} {t('Logins')}
                  </StyledBadge>
                </span>
              </CardTitle>
              
              {Object.entries(centerData.students).map(([studentId, studentData]) => (
                <div key={studentId} className="mb-3">
                  <h6>
                    <User size={16} className="me-1" />
                    {studentData.name}
                    {studentData.isDemoStudent && (
                      <StyledBadge color="warning">{t('Demo Student')}</StyledBadge>
                    )}
                    {hasExceededLoginLimit(studentData) && (
                      <StyledBadge color="danger">{t('Limit Exceeded')}</StyledBadge>
                    )}
                  </h6>
                  
                  {hasExceededLoginLimit(studentData) && !studentData.isDemoStudent && (
                    <LoginLimitWarning>
                      <AlertCircle size={16} />
                      {t('This student has reached or exceeded the weekly login limit of 2 logins.')}
                    </LoginLimitWarning>
                  )}
                  
                  <StyledTable bordered responsive striped className="mt-2">
                    <TableHeader>
                      <tr>
                        <th>{t('Date & Time')}</th>
                        <th>{t('Login Type')}</th>
                        <th>{t('Device')}</th>
                        <th>{t('IP Address')}</th>
                        <th>{t('Browser')}</th>
                      </tr>
                    </TableHeader>
                    <tbody>
                      {studentData.logins.map((login, loginIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={loginIndex}>
                          <td>
                            <Calendar size={14} className="me-1" />
                            {moment(login.created_at).format('YYYY-MM-DD')}
                            <br />
                            <Clock size={14} className="me-1" />
                            {moment(login.created_at).format('HH:mm:ss')}
                          </td>
                          <td>
                          <LoginTypeCell>
                            {login.login_type === 'pin' ? (
                              <>
                                <Key size={16} />
                                {t('PIN')}
                              </>
                            ) : (
                              <>
                                <QrCode size={16} />
                                {t('QR')}
                              </>
                            )}
                          </LoginTypeCell>
                          </td>
                          <DeviceTypeCell>
                            {login.browser_info?.mobile ? (
                              <>
                                <Smartphone size={16} />
                                {t('Mobile')}
                              </>
                            ) : (
                              <>
                                <Monitor size={16} />
                                {t('Tablet')}
                              </>
                            )}
                          </DeviceTypeCell>
                          <td>{login.ip_address}</td>
                          <td>
                            {login.browser_info?.browser} on {login.browser_info?.platform}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                </div>
              ))}
              
              <div className="bg-secondary text-white p-2 mt-3">
                <strong>
                  {t('Total for')} {centerName}: {centerData.totalStudents} {t('students')}, 
                  {centerData.totalLogins} {t('logins')}, 
                  {centerData.pinLogins} {t('PIN logins')}, 
                  {centerData.qrLogins} {t('QR logins')}
                </strong>
              </div>
            </CardBody>
          </Card>
        ))}
        
        <Card className="mb-4">
          <CardBody>
            <CardTitle tag="h5">{t('Login Summary')} ({moment().format("YYYY-MM-DD HH:mm")})</CardTitle>
            <Row>
              <Col md={4} className="mb-3">
                <DetailCard>
                  <CardBody>
                    <h5>{t('Total Logins')}</h5>
                    <h2>{filteredData.totalLogins}</h2>
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                        <Key size={16} className="me-1" />
                        {t('PIN')}: {filteredData.pinLogins} ({filteredData.totalLogins > 0 ? Math.round((filteredData.pinLogins / filteredData.totalLogins) * 100) : 0}%)
                      </div>
                      <div>
                        <QrCode size={16} className="me-1" />
                        {t('QR')}: {filteredData.qrLogins} ({filteredData.totalLogins > 0 ? Math.round((filteredData.qrLogins / filteredData.totalLogins) * 100) : 0}%)
                      </div>
                    </div>
                  </CardBody>
                </DetailCard>
              </Col>
              <Col md={4} className="mb-3">
                <DetailCard>
                  <CardBody>
                    <h5>{t('Student Types')}</h5>
                    <h2>{filteredData.totalStudents} {t('Students')}</h2>
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                        {t('Regular')}: {filteredData.regularLogins} ({filteredData.totalLogins > 0 ? Math.round((filteredData.regularLogins / filteredData.totalLogins) * 100) : 0}%)
                      </div>
                      <div>
                        {t('Demo')}: {filteredData.demoLogins} ({filteredData.totalLogins > 0 ? Math.round((filteredData.demoLogins / filteredData.totalLogins) * 100) : 0}%)
                      </div>
                    </div>
                  </CardBody>
                </DetailCard>
              </Col>
              <Col md={4} className="mb-3">
                <DetailCard>
                  <CardBody>
                    <h5>{t('Device Types')}</h5>
                    <h2>{filteredData.totalLogins} {t('Logins')}</h2>
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                        <Smartphone size={16} className="me-1" />
                        {t('Mobile')}: {filteredData.mobileLogins} ({filteredData.totalLogins > 0 ? Math.round((filteredData.mobileLogins / filteredData.totalLogins) * 100) : 0}%)
                      </div>
                      <div>
                        <Monitor size={16} className="me-1" />
                        {t('Tablet')}: {filteredData.desktopLogins} ({filteredData.totalLogins > 0 ? Math.round((filteredData.desktopLogins / filteredData.totalLogins) * 100) : 0}%)
                      </div>
                    </div>
                  </CardBody>
                </DetailCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  const renderCalendarView = () => {
    if (isLoading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{error}</div>;
    if (loginData.dailyStats.length === 0) return <div>{t('No data available')}</div>;
    
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    
    // Calculate the first day of the month (0 = Sunday, 6 = Saturday)
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
    
    // Create calendar weeks array
    const calendarWeeks = [];
    let dayCounter = 1;
    let week = Array(7).fill(null);
    
    // Fill in empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      week[i] = null;
    }
    
    // Fill in the days of the month
    for (let i = firstDayOfMonth; i < 7; i++) {
      if (dayCounter <= daysInMonth) {
        week[i] = dayCounter++;
      }
    }
    
    calendarWeeks.push([...week]);
    
    // Fill in the rest of the weeks
    while (dayCounter <= daysInMonth) {
      week = Array(7).fill(null);
      
      for (let i = 0; i < 7; i++) {
        if (dayCounter <= daysInMonth) {
          week[i] = dayCounter++;
        }
      }
      
      calendarWeeks.push([...week]);
    }
    
    return (
      <div>
        <h4 className="mb-3">{t('Login Calendar View')}</h4>
        <table className="table table-bordered calendar-table">
          <thead>
            <tr>
              {dayNames.map(day => (
                <th key={day} className="text-center">{t(day)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {calendarWeeks.map((week, weekIndex) => (
              //eslint-disable-next-line
              <tr key={weekIndex} style={{ height: '100px' }}>
                {week.map((day, dayIndex) => {
                  if (!day) {
                    //eslint-disable-next-line
                    return <td key={`empty-${dayIndex}`}></td>;
                  }
                  
                  const dayStats = loginData.dailyStats[day - 1];
                  console.log(loginData);
                  const hasLogins = dayStats && dayStats.totalLogins > 0;
                  const bgColor = hasLogins 
                    ? `rgba(251, 150, 120, ${Math.min(dayStats.totalLogins / 10, 1)})`
                    : '';
                  
                  return (
                    <td 
                      key={`day-${day}`} 
                      className="position-relative" 
                      style={{ backgroundColor: bgColor, verticalAlign: 'top' }}
                    >
                      <div className="d-flex justify-content-between">
                        <span className="fw-bold">{day}</span>
                        {hasLogins && (
                          <span style={{fontSize:18}} className="badge bg-danger"><UserCheck size={20} className="me-1"/>{dayStats.uniqueStudents}</span>
                        )}
                      </div>
                      {hasLogins && (
                        <div className="small mt-1">
                          <div><Key size={12} className="me-1" /> {dayStats.pinLogins}</div>
                          <div><QrCode size={12} className="me-1" /> {dayStats.qrLogins}</div>
                          <div style={{ fontSize: '0.7rem' }}>
                            <div>{t('Demo')}: {dayStats.demoLogins}</div>
                            <div>{t('Regular')}: {dayStats.regularLogins}</div>
                          </div>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderChartView = () => {
    return (
      <div className="mt-4">
        <h4>{t('Login Activity Analysis')}</h4>
        <LoginActivityChart 
          data={loginDataByDay}
          month={selectedMonth}
          year={selectedYear}
        />
      </div>
    );
  };

  const isMonthDisabled = (monthValue) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // For current year
    if (selectedYear === currentYear) {
      // Disable future months
      return monthValue > currentMonth;
    }
    
    // For past years, enable all months
    if (selectedYear < currentYear) {
      return false;
    }
    
    // For future years, disable all months
    if (selectedYear > currentYear) {
      return true;
    }
  
    return false;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'summary':
        return renderSummaryView();
      case 'detailed':
        return renderDetailedView();
      case 'calendar':
        return renderCalendarView();
      case 'chart':
        return renderChartView();
      default:
        return renderSummaryView();
    }
  };

  const PrintableContent = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <Card>
        <CardBody>
          <h2 className="mb-4">{t('Student Login Report')} - {months[selectedMonth].name} {selectedYear}</h2>
          {isLoading ? (
            <div>{t('Loading...')}</div>
          ) : error ? (
            <Alert color="danger">
              <AlertCircle className="h-4 w-4" />
              <h4>Error</h4>
              <p>{error}</p>
            </Alert>
          ) : (
            <>
              {renderSummaryView()}
              {renderDetailedView()}
              {renderChartView()}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  ));

  return (
    <Container>
      <h2 className="my-4">{t('Student Login Report')}</h2>
      
      <FilterCard>
        <CardBody>
          {renderFilters()}
          <Row className="mb-3">
            <Col xs={12} className="mb-3">
              <div className="d-flex flex-wrap">
                {months.map((month) => (
                  <Button
                    key={month.value}
                    color={selectedMonth === month.value ? "primary" : "secondary"}
                    onClick={() => handleMonthChange(month.value)}
                    disabled={isMonthDisabled(month.value)}
                    className="mb-2 me-2"
                  >
                    {month.name}
                  </Button>
                ))}
              </div>
            </Col>
            <Col xs={12} sm={6} md={3} className="mb-3">
              <Input
                type="number"
                value={selectedYear}
                onChange={handleYearChange}
                min="2020"
                max={new Date().getFullYear()}
              />
            </Col>
            <Col xs={12} sm={6} md={9} className="text-md-end">
              <Button
                color="success"
                onClick={handlePrint}
                className="me-2 mb-2"
              >
                {t('Print Report')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </FilterCard>

      <Card className="mb-4">
        <CardBody>
          <div className="mb-3">
            <TabButton
              color={activeTab === 'summary' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('summary')}
            >
              {t('Summary View')}
            </TabButton>
            <TabButton
              color={activeTab === 'detailed' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('detailed')}
            >
              {t('Detailed View')}
            </TabButton>
            <TabButton
              color={activeTab === 'calendar' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('calendar')}
            >
              {t('Calendar View')}
            </TabButton>
            <TabButton
              color={activeTab === 'chart' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('chart')}
            >
              {t('Chart View')}
            </TabButton>
          </div>
          
          {renderTabContent()}
        </CardBody>
      </Card>

      <div style={{ display: 'none' }}>
        <div ref={contentRef}>
          <PrintableContent />
        </div>
      </div>

      <Button
        color="success"
        onClick={handlePrint}
        className="mt-3"
      >
        {t('Print Report')}
      </Button>
    </Container>
  );
};

export default StudentLoginReport;