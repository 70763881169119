import fetchRequest from '../auth/FetchInterceptorRequests'

const studentLoginService = {}

/**
 * Get all login data for a specific date range
 * 
 * @param {string} startDate - ISO date string for start date
 * @param {string} endDate - ISO date string for end date
 * @returns {Promise} Promise with login data
 */
studentLoginService.getLoginsByDateRange = function(startDate, endDate) {
  return fetchRequest({
    url: `/digital/student-logins/by-date-range`,
    method: 'get',
    params: { start: startDate, end: endDate }
  })
}

/**
 * Get login history for a specific student
 * 
 * @param {string} studentId - Student ID
 * @param {number} limit - Maximum number of records to return
 * @returns {Promise} Promise with login history
 */
studentLoginService.getLoginHistoryByStudent = function(studentId, limit = 10) {
  return fetchRequest({
    url: `/digital/student-logins/${studentId}`,
    method: 'get',
    params: { limit }
  })
}

/**
 * Get login statistics for the system
 * 
 * @param {number} days - Number of days to look back
 * @returns {Promise} Promise with login statistics
 */
studentLoginService.getLoginStats = function(days = 7) {
  return fetchRequest({
    url: `/digital/student-logins/stats`,
    method: 'get',
    params: { days }
  })
}

/**
 * Get active logins for a student
 * 
 * @param {string} studentId - Student ID
 * @returns {Promise} Promise with active logins
 */
studentLoginService.getActiveLogins = function(studentId) {
  return fetchRequest({
    url: `/digital/student-logins/${studentId}/active`,
    method: 'get'
  })
}

/**
 * Invalidate all active login sessions for a student
 * 
 * @param {string} studentId - Student ID
 * @returns {Promise} Promise with success message
 */
studentLoginService.invalidateAllLogins = function(studentId) {
  return fetchRequest({
    url: `/digital/student-logins/${studentId}/invalidate`,
    method: 'post'
  })
}

/**
 * Get login data by country
 * 
 * @param {string} countryId - Country ID
 * @param {string} startDate - ISO date string for start date
 * @param {string} endDate - ISO date string for end date
 * @returns {Promise} Promise with login data filtered by country
 */
studentLoginService.getLoginsByCountry = function(countryId, startDate, endDate) {
  return fetchRequest({
    url: `/digital/student-logins/by-country/${countryId}`,
    method: 'get',
    params: { start: startDate, end: endDate }
  })
}

/**
 * Get login data by center
 * 
 * @param {string} centerId - Center ID
 * @param {string} startDate - ISO date string for start date
 * @param {string} endDate - ISO date string for end date
 * @returns {Promise} Promise with login data filtered by center
 */
studentLoginService.getLoginsByCenter = function(centerId, startDate, endDate) {
  return fetchRequest({
    url: `/digital/student-logins/by-center/${centerId}`,
    method: 'get',
    params: { start: startDate, end: endDate }
  })
}

/**
 * Get students who have exceeded their login limits
 * 
 * @returns {Promise} Promise with exceeded limits data
 */
studentLoginService.getStudentsWithExceededLimits = function() {
  return fetchRequest({
    url: `/digital/student-logins/exceeded-limits`,
    method: 'get'
  })
}

export default studentLoginService;